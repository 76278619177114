.mottive-logo{
    
    &.glow{
        animation: on 3s forwards;
    }

}

@keyframes on{
    0%{
        filter: drop-shadow(0px 0px 0px #000);
    }
    60%{
        filter: drop-shadow(0px 0px 6px #2db192);
    }
    100%{
        filter: drop-shadow(0px 0px 0px #2db192);
    }
}