svg #wheel {
	animation: scroll ease 1.5s infinite;
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(60px);
	}
    100%{
        opacity: 0;
		transform: translateY(60px);
    }
}