.stage{
    position: relative;
    background-color: #14171f;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;

    .page{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        scroll-snap-align: start;

        &.cinza{
            background-color: #f7f7fc;
        }
    }
    
}