span{

    position: relative;

    .cover{
        display: inline;
        color: transparent;
    }  

    .default{
        display: inline;
        position: absolute;
        left: 0;
        top: 0;
    }

    .new{
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        color: #2db192;
        text-shadow: 0px 0px 5px #2db192;
    }

    &.aparecer-verde{
        
        .default{
            visibility: hidden;
        }

        .new{
            display: inline;
        }

    }

}