.page-one{
    height: 100vh;
    width: 100%;
}

.mensagem{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #fff;
    font-size: 2rem;
    line-height: 1;
    letter-spacing: -0.25px;

    .texto{
        text-align: center;
    }

}

.mensagem-2{
    font-size: clamp(1rem, 5.5vw, 4rem);
    font-weight: bold;
}

.mottive{
    color: #2db192;
}

.scroll-animation-icon{
    position: absolute;
    left: 50%;
    bottom: 5%;
}