.is-mark{
    white-space: nowrap;
    font-weight: bold;
    position: relative;
}
mark{
    background-color: transparent;
}
mark.highlight{
    white-space: nowrap;
    font-weight: bold;
    position: relative;
    background-color: transparent;
    transition: all 0.2s ease-out;
    cursor: pointer;

    &:hover{
        //background-color: #f00;
    }

    &::before{
        //transform: scaleX(1);
    }

    &::before{
        transform-origin: 0% 50%;
        position: absolute;
        z-index: -1 ;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        background: linear-gradient(
            104deg, 
            rgba(130, 255, 173,0) 0.9%,
            rgba(130, 255, 173,1.25) 2.4%, 
            rgba(130, 255, 173,0.5) 5.8%, rgba(130, 255, 173,0.1) 93%, 
            rgba(130, 255, 173,0.7) 96%,
            rgba(130, 255, 1732,0) 98%), 
            linear-gradient(
                183deg, 
                rgba(130, 255, 173,0) 0%, 
                rgba(130, 255, 173,0.3) 7.9%,
                rgba(130, 255, 173,0) 15%
            )
        ;
        padding: 0.1em 5px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        margin: 0;
        border-radius: 7.5px;
        text-shadow: -12px 12px 9.8px rgba(130, 255, 173,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1);
        //transition: transform 0.2s ease-out;
        animation: main 500ms forwards;
    }
}

@keyframes main {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }