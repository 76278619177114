.subtitle{
    color: #707070;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
    overflow: hidden;
}

.page-wrapper{
    padding: 12vh 0;
}

.wrapper-text, .wrapper{
    max-width: 1116px;
    padding: 0 60px;
    margin: 0 auto;
    width: 100%;
}

h2.title, h1.title{
    font-size: 48px;
    line-height: 60px;
}

h1.supertitle{
    font-size: 80px;
    line-height: 90px;
}

.title-marginbottom{
    margin-bottom: 64px;
}

h3.title{
    font-size: 28px;
    line-height: 40px;
}

.descricao{
    color: #707070;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

@media all and (max-width: 699px) {

    h2.title, h1.title{
        font-size: 28px !important;
        line-height: 38px !important;
    }
    
    h1.supertitle{
        font-size: 40px !important;
        line-height: 50px !important;
    }
    
    h3.title{
        font-size: 18px !important;
        line-height: 25px !important;
    }

    .subtitle{
        font-size: 10px;
    }

    .wrapper-text, .wrapper{
        padding: 0 20px;
        width: 100%;
    }

 }