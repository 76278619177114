.contato-1{
    position: relative;
    width: 100%;

    .texto{
        position: relative;
        margin-top: 22vh;
        margin-bottom: 22vh;
        left: 40vw;
        width: 45vw;
        line-height: 1.33;
        font-size: 1.75rem;
    }
}

.contato-2{
    background-color: #000;
    height: 100vh;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        //opacity: 0.05;
    }

}


@media all and (max-width: 699px) {

    .contato-1{
    
        .texto{
            left: 0vw;
            width: 96vw;
            line-height: 1;
            font-size: 1.2rem;
            text-align: right;
        }
    }
    
}